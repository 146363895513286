












































































































































































































/* eslint-disable  */
import Vue from "vue";

import { FieldPassword, FieldUser } from "@/components/account";
import AllowIf from "@/components/AllowIf";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifySuccess } from "@/components/Notification";
import { AxiosError } from "axios";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import Worker from "@/models/Worker";
import { omitBy } from "lodash";
import FieldOcupations from "@/components/account/FieldOcupations.vue";

export default Vue.extend({
  name: "update-employee",
  components: {
    FieldPassword,
    AllowIf,
    FieldOcupations,
    FieldUser,
  },
  data() {
    return {
      dialog: false,
      passwordRetry: "",
      formValid: false,
      rules: {
        ...rules,
        maxCaracters: (v: string) => v.length >= 3 || this.$t("min5Chars"),
      },
      ocupattions: [],
      loadingOcupations: false,
      editMode: false,
      form: {
        email: "",
        username: "",
        password: "",
        fullname: "",
        phone: "",
        ocupationId: 0,
        rol: "",
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
      },
      uuid: "",
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["loading"]),
    ...mapState("crmCompaniesModule", ["companies"]),
    ...mapGetters(["isSuper", "isAdmin", "isWorker", "isDoctor"]),
    ...mapGetters({ methodCall: "getMethodCalls" }),
    passwordIsRight(): boolean {
      const { passwordRetry, form } = this;
      const same = passwordRetry == form.password;
      const notEmpty = passwordRetry.trim() != "" && form.password.trim() != "";
      // const maxLength = passwordRetry.length >= 8 && form.password.length >= 8;

      return same && notEmpty;
    },
    samePassword(): boolean {
      return this.passwordRetry.trim() == this.form.password.trim();
    },
    enableCreate(): boolean {
      return this.passwordIsRight && this.formValid;
    },
    showForm(): boolean {
      return (this.companies.length > 0 && this.isSuper) || this.isAdmin;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("crmEmployeeModule", [
      "actCreateEmployee",
      "actGetCompanies",
      "actUpdateWorker",
    ]),
    ...mapMutations("rules.required", ["mutLoading"]),
    ...mapMutations("crmEmployeeModule", ["mutLoading"]),
    update() {
      let reference = Object.assign({}, this.form);
      // Remove empties keys
      if (reference.phone != "" && reference.phone != undefined) {
        reference.phone = "+1" + reference.phone;
      }
      if (reference.payXhour != 0) {
        reference.payXhour = Number(reference.payXhour);
      }
      if (reference.hoursToWork != 0) {
        reference.hoursToWork = Number(reference.hoursToWork);
      }
      const cleanBody = omitBy(reference, (key) => key == "");

      // Validations
      if (
        this.passwordIsRight == false &&
        this.passwordRetry !== "" &&
        this.form.password !== ""
      ) {
        notifyError("The passwords don't match");
        return;
      }

      if (
        this.rules.isPhone(this.form.phone) !== true &&
        this.form.phone !== ""
      ) {
        notifyError((this as any).rules.isPhone(this.form.phone));
        return;
      }

      if (
        this.rules.email(this.form.email) !== true &&
        this.form.email !== ""
      ) {
        notifyError((this as any).rules.email(this.form.email));
        return;
      }

      this.actUpdateWorker({ uuid: this.uuid, body: cleanBody })
        .then(() => {
          notifySuccess("Employee updated succes successfully");
          this.dialog = false;
          this.mutLoading(false);
        })
        .catch((error: any) => {
          this.mutLoading(false);

          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetForm(): void {
      this.passwordRetry = "";
      this.form = {
        username: "",
        email: "",
        password: "",
        fullname: "",
        phone: "",
        ocupationId: 0,
        rol: "",
        payXhour: 0,
        hoursToWork: 0,
        origPhone: "",
        ext: "",
      };

      if (this.$refs.form) {
        (this as any).$refs.form.resetValidation();
      }

      this.init();
    },
    init() {
      if (this.isSuper) {
        this.actGetCompanies();
      }
    },
    setToEdit(employee: Worker) {
      let idocupation = 0;
      if (employee.ocupation != null) {
        idocupation = employee.ocupation.id;
      }
      this.form.username = employee.username;
      this.form.email = employee.email;
      this.form.fullname = employee.fullname;
      this.form.phone =
        employee.phone != null ? employee.phone.replace("+1", "") : "";
      this.form.ocupationId = idocupation;
      this.form.rol = employee.rol;
      this.form.hoursToWork = employee.hoursToWork;
      this.form.payXhour = employee.payXhour;
      this.uuid = employee.uuid;
      if (this.methodCall != "twilio") {
        this.form.ext = employee.ext ? employee.ext : "";
        this.form.origPhone =
          employee.origPhone != null
            ? employee.origPhone.replace("+1", "")
            : "";
      }

      this.editMode = true;
      this.init();
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editMode = false;
      this.form.password = "";
      this.form.email = "";
      this.form.fullname = "";
      this.form.phone = "";
      this.passwordRetry = "";
      this.form.ocupationId = 0;
      this.form.payXhour = 0;
      this.form.hoursToWork = 0;
      this.form.rol = "";
      this.form.username = "";
      this.mutLoading(false);
    },
  },
});
