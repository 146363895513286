


































































































import Vue from "vue";
import { rolIcon, rolColor, statusColor } from "@/utils/rolesStatus";

export default Vue.extend({
  name: "employee-details",
  props: {
    value: Boolean,
    employe: Object,
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    rolColor,
  },
});
