var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"elevation-15",attrs:{"value":_vm.value,"width":"280","absolute":"","right":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-window-close")])],1),_c('span',{staticClass:"ml-3"},[_vm._v("Details")])],1)],1),_c('v-divider'),_c('v-layout',{staticClass:"pa-3",attrs:{"align-center":"","column":"","justify-center":""}},[_c('v-avatar',{staticClass:"mr-3 elevation-10",attrs:{"size":"94","color":_vm.rolColor(_vm.employe.rol)}},[(_vm.employe.avatar)?_c('img',{attrs:{"src":_vm.employe.avatar}}):_c('span',{staticClass:"text-h5 font-weight-regular white--text"},[_vm._v(" "+_vm._s(_vm.employe.fullname.slice(0, 2))+" ")])]),_c('div',{staticClass:"text-h6 mt-3"},[_vm._v(_vm._s(_vm.employe.fullname))]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.employe.phone))]),_c('v-btn',{staticClass:"text-caption rounded-lg",attrs:{"href":'mailto:' + _vm.employe.email,"link":"","small":"","text":""}},[_vm._v(" "+_vm._s(_vm.employe.email)+" ")]),_c('v-layout',{staticClass:"mt-5 fill-width",attrs:{"align-center":"","justify-space-around":""}},[_c('v-tooltip',{attrs:{"transition":"fade-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":"green","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-phone")])],1)]}}])},[_c('span',[_vm._v("Contact")])]),_c('v-tooltip',{attrs:{"transition":"fade-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":"pink darken-1","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-calendar-plus")])],1)]}}])},[_c('span',[_vm._v("Book")])]),_c('v-tooltip',{attrs:{"transition":"fade-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":"purple darken-1","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-basket-plus")])],1)]}}])},[_c('span',[_vm._v("Sell")])])],1)],1),_c('v-divider',{staticClass:"my-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }